import React, { useState, useEffect, useRef } from "react";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Modal,
  TextField,
  Typography,
  InputAdornment,
  IconButton
} from "@mui/material";
import { useAuth } from "../../context/auth-context";
import { useTranslation } from "react-i18next";
import { useRouter } from 'next/router';
import { Visibility, VisibilityOff } from "@mui/icons-material";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "40%", sm: "40%", md: "40%", lg: "40%", xl: "20%" },
  heigth: { xs: "60%", sm: "60%", md: "60%", lg: "60%", xl: "30%" },
  bgcolor: "background.paper",
  border: "2px solid #FFF",
  boxShadow: 24,
  p: 3,
};
type registerProps = {
  open?: any;
  onClose?: any;
  messageNotRegistered?: any;
  id?: any;
  emailRegister?: any;
};

export const RegisterUserFirebase = ({
  open,
  onClose,
  messageNotRegistered,
  id,
  emailRegister,
}: registerProps) => {
  const { register, errorRegister, messageSuccess, login } = useAuth() as any;
  const [email, setEmail] = useState(emailRegister);
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [userGuest, setUserGuest] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation();

  const router = useRouter();
  const guest = router.query.guest;
  const decodedGuest = decodeURIComponent(typeof guest === 'string' ? guest : (guest ?? []).join(','));
  const [isRegistrationSuccessful, setIsRegistrationSuccessful] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [hasStartedConfirmPassword, setHasStartedConfirmPassword] = useState(false);

  const isButtonDisabled = !isChecked || !email || !password || (guest && !name) || !passwordsMatch;

  useEffect(() => {
    if (guest) {
      setEmail(decodedGuest);
      setUserGuest(true);
    }
  }, []);

  const handleEmailChange = (e: any) => setEmail(e.target.value);
  const handlePhoneChange = (e: any) => setPhone(e.target.value);
  const handleNameChange = (e: any) => setName(e.target.value);
  const handlePasswordChange = (e: any) => {
    setPassword(e.target.value);
  };
  const handleConfirmPasswordChange = (e: any) => {
    setConfirmPassword(e.target.value);
    setHasStartedConfirmPassword(true);
  };
  const handleCheckboxChange = (event: any) => {
    setIsChecked(event.target.checked);
  };
  const handleClose = () => onClose();
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (hasStartedConfirmPassword) {
      setPasswordsMatch(password === confirmPassword);
    }
  }, [password, confirmPassword, hasStartedConfirmPassword]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      await register(email, password, name, phone, userGuest);
    } catch (error: any) {
      console.error("error Register", error.message);
    }
  };

  useEffect(() => {
    if (messageSuccess !== "") {
      setIsRegistrationSuccessful(true);
    }
  }, [messageSuccess]);



  useEffect(() => {
    if (messageSuccess && !errorRegister) {
      handleClose();
    }
  }, [messageSuccess, errorRegister, handleClose]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableEnforceFocus
    >
      <Box sx={style}>
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignContent="center"
        >
          {messageNotRegistered && (
            <Typography
              id={id}
              sx={{
                fontSize: "1rem",
                fontWeight: "bold",
                color: "#005f40",
                textAlign: "center",
                marginBottom: "3%",
              }}
            >
              {t("Login.message.notRegistered")}
            </Typography>
          )}
          <form onSubmit={handleSubmit} noValidate>
            <TextField
              margin="normal"
              fullWidth
              required={userGuest}
              id={`${id}-name`}
              label={t("Login.name")}
              value={name}
              onChange={handleNameChange}
              color="success"
              autoFocus
              inputProps={{
                style: { padding: "12px 9px", fontSize: "1rem" },
              }}
            />
            <TextField
              margin="normal"
              fullWidth
              id={`${id}-phone`}
              label={t("Login.phone")}
              value={phone}
              onChange={handlePhoneChange}
              color="success"
              autoFocus
              inputProps={{
                style: { padding: "12px 9px", fontSize: "1rem" },
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id={`${id}-email`}
              label={t("Login.email")}
              defaultValue={decodedGuest !== '' ? decodedGuest : email}
              disabled={decodedGuest !== ''}
              onChange={handleEmailChange}
              autoComplete="email"
              color="success"
              autoFocus
              inputProps={{
                style: { padding: "12px 9px", fontSize: "1rem" },
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id={`${id}-password`}
              label={t("Login.password")}
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={handlePasswordChange}
              color="success"
              autoComplete="current-password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      onClick={handleTogglePassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              inputProps={{
                style: { padding: "12px 9px", fontSize: "1rem" },
              }}
              sx={{ marginBottom: "3%" }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id={`${id}-confirmPassword`}
              label={t("Login.confirmPassword")}
              type={showPassword ? "text" : "password"}
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              color="success"
              error={hasStartedConfirmPassword && !passwordsMatch}
              helperText={hasStartedConfirmPassword && !passwordsMatch && t("Login.passwordsDoNotMatch")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      onClick={handleTogglePassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              inputProps={{
                style: { padding: "12px 9px", fontSize: "1rem" },
              }}
              sx={{ marginBottom: "3%" }}
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  sx={{
                    color: "#005f40",
                    fontSize: "1rem",
                    "&.Mui-checked": {
                      color: "#005f40",
                    },
                  }}
                />
              }
              sx={{ color: "#005f40" }}
              label={
                <label>
                  <a href="/terminos" target="_blank">
                    {t("Login.acceptedTerms")}
                  </a>
                </label>
              }
            />

            {errorRegister && <Alert severity="error">{errorRegister}</Alert>}
            {messageSuccess && (
              <Alert severity="success">{messageSuccess}</Alert>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={isButtonDisabled}
              sx={{
                mt: 4,
                mb: 1,
                padding: "10px 0px",
                borderRadius: "25px",
                backgroundColor: "#005f40",
                color: "#EDEDED",
                fontSize: "1rem",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#005f40",
                  color: "#EDEDED",
                },
              }}
            >
              {t("Login.buttonRegister")}
            </Button>
          </form>
        </Box>
      </Box>
    </Modal>
  );
};
