import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store";

interface CoordinatesState {
    coords: string;
}

const initialState: CoordinatesState = {
    coords: "",
};

export const coordinatesSlice = createSlice({
    name: "coordinates",
    initialState,
    reducers: {
        setCoordinates: (state, action) => {
            state.coords = action.payload;
        },
    },
});

export const { setCoordinates } = coordinatesSlice.actions;
export const selectCoordinatesPolygon = (state: RootState) => state.coordinates;

export const coordinatePolygonReduce = coordinatesSlice.reducer;
